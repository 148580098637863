import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "../assets/css/slider.css";

const data1 = [
  {
    image: require("../assets/templates/cat1.png"),
  },
  {
    image: require("../assets/templates/cat2.png"),
  },

  {
    image: require("../assets/templates/cat4.png"),
    // caption: "Caption 4",
    // description: "Description 4",
  },
  {
    image: require("../assets/templates/cat5.png"),
    // caption: "Caption 5",
    // description: "Description 5",
  },
  {
    image: require("../assets/templates/cat6.png"),
    // caption: "Caption 6",
    // description: "Description 6",
  },
  {
    image: require("../assets/templates/cat7.png"),
  },
  {
    image: require("../assets/templates/cat8.png"),
  },
  {
    image: require("../assets/templates/cat9.png"),
  },
  {
    image: require("../assets/templates/cat10.png"),
  },
  {
    image: require("../assets/templates/cat11.png"),
  },
  {
    image: require("../assets/templates/cat12.png"),
  },
  {
    image: require("../assets/templates/cat13.png"),
  },
  {
    image: require("../assets/templates/cat14.png"),
  },
  {
    image: require("../assets/templates/cat15.png"),
  },
  {
    image: require("../assets/templates/cat16.png"),
  },
  {
    image: require("../assets/templates/cat17.png"),
  },
  {
    image: require("../assets/templates/cat18.png"),
  },
  {
    image: require("../assets/templates/cat19.png"),
  },
  {
    image: require("../assets/templates/cat20.png"),
  },
  {
    image: require("../assets/templates/cat3.png"),
  },
];

export default function SliderChain() {
  const swiperRef1 = useRef(null);

  const handleMouseEnter = (swiper) => {
    if (swiper && swiper.autoplay) {
      swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = (swiper) => {
    if (swiper && swiper.autoplay) {
      swiper.autoplay.start();
    }
  };

  return (
    <div className="swiper-container">
      <Swiper
        ref={swiperRef1}
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={30}
        slidesPerView={3}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        navigation
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        onMouseEnter={() => handleMouseEnter(swiperRef1.current)}
        onMouseLeave={() => handleMouseLeave(swiperRef1.current)}
      >
        {data1.map((slide, i) => (
          <SwiperSlide key={i} className="swiper-slide">
            <img src={slide.image} alt={`Slide ${i + 1}`} />
            {/* <div className="carousel-caption">
              <h3>{slide.caption}</h3>
              <p>{slide.description}</p>
            </div> */}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
